// src/Checkout.js
import React from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header';
import { createCheckoutSession, stripePromise } from './stripe';

const products = [
  { id: 'prod_QbUQvaXWX49NWw', title: '超上級単語特急 暗黒のフレーズ', price: 1980, imgSrc: '/images/asahi_ankoku_phrase.jpg' },
  { id: 'prod_QbUSMpx0NDEVIn', title: '文法問題 でる1000問', price: 3980, imgSrc: '/images/ask_deru1000.jpg' },
  { id: 'prod_QbUSv05cNXkjty', title: 'DUO 3.0', price: 1980, imgSrc:'/images/duo.jpg' },
  { id: 'prod_QbUShPHisY1tiQ', title: 'TOEIC L&R TEST 990点攻略 文法・語彙問題 1000', price: 3980, imgSrc: '/images/oubunsha_990_goi.jpg' },
  { id: 'prod_QbUSM4qQ9PZcpD', title: '実践 IELTS 英単語 3500', price: 4980, imgSrc:'/images/oubunsha_passtan_1q_5.jpg' },
  { id: 'prod_QbUSR28QF5DmrG', title: '英検 1級 でる順パス単 5訂版', price: 2980, imgSrc: '/images/tokyu_ginhure_new.jpg' },
  { id: 'prod_QbUS9JRnqBbpal', title: 'TOEIC L&R TEST 出る単特急 銀のフレーズ', price: 1980, imgSrc:'/images/tokyu_ginhure_new.jpg'},
  { id: 'prod_QbUScYiBTjYyXb', title: 'TOEIC L&R TEST 出る単特急 金のセンテンス', price: 1980, imgSrc: '/images/tokyu_kin_sentence.jpg' },
  { id: 'prod_QbUSUV3L6ihDss', title: 'TOEIC L&R TEST 出る単特急 金のフレーズ', price: 1980, imgSrc: '/images/tokyu_kinhure_new.jpg' },
  { id: 'prod_QbUSruHfc4BdlP', title: 'TOEIC L&R TEST 上級単語特急 黒のフレーズ', price: 1980, imgSrc: '/images/tokyu_kurohure_new.jpg' },
  { id: 'prod_QbUSCAVTUYcSGP', title: 'Distinction 1', price: 3980, imgSrc: '/images/D1BAND_1080x.webp'},
  { id: 'prod_QbUSdyJJxSNna3', title: 'Distinction 2', price: 3980, imgSrc: '/images/D2BAND_530x@2x.webp' },
  //{ id: 'prod_QbpmCGTo2oSLia', title: 'TEST', price: 100 ,imgSrc:'/images/D2BAND_530x@2x.webp'},
];

const Checkout = () => {
  const { id } = useParams();
  const product = products.find(product => product.id === id);

  if (!product) {
    return <div>Product not found</div>;
  }

  const handleBuyNow = async () => {
    try {
      const session = await createCheckoutSession(product.id, product.price);
      const stripe = await stripePromise;
      stripe.redirectToCheckout({ sessionId: session.id });
    } catch (error) {
      console.error('Error redirecting to checkout:', error);
    }
  };

  return (
    <>
      <Header />
      <div className="product-page">
        <div className="product-page__image-container">
          <img src={product.imgSrc} alt={product.title} className="product-page__image" />
        </div>
        <div className="product-page__info">
          <h2 className="product-page__title">{product.title}</h2>
          <p className="product-page__price">¥{product.price}</p>
          <button className="product-page__buy-button" onClick={handleBuyNow}>
            購入
          </button>
        </div>
      </div>
    </>
  );
};

export default Checkout;
