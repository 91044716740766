import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../CartContext';

const SuccessPage = () => {
  const { clearCart } = useCart();
  const navigate = useNavigate();
  const [driveLink, setDriveLink] = useState('');

  useEffect(() => {
    clearCart();
    
    // Google DriveのリンクをFetchする関数
    const fetchDriveLink = async () => {
      try {
        // ここでバックエンドAPIを呼び出してGoogle DriveのリンクをFetchする
        const response = await fetch('/api/get-drive-link');
        const data = await response.json();
        if (data.driveLink) {
          setDriveLink(data.driveLink);
        }
      } catch (error) {
        console.error('Error fetching drive link:', error);
      }
    };

    fetchDriveLink();

    // 30秒後にホームページにリダイレクト（時間を延長）
    const timer = setTimeout(() => {
      navigate('/');
    }, 30000);

    return () => clearTimeout(timer);
  }, [clearCart, navigate]);

  return (
    <div className="success-page">
      <h1>購入が完了しました！</h1>
      <p>ありがとうございます。</p>
      {driveLink ? (
        <p>
          以下のリンクから商品にアクセスできます：
          <a href={driveLink} target="_blank" rel="noopener noreferrer">Google Driveリンク</a>
        </p>
      ) : (
        <p>商品へのアクセスリンクを準備中です。しばらくお待ちください。</p>
      )}
      <p>30秒後にホームページに戻ります...</p>
    </div>
  );
};

export default SuccessPage;